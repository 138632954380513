import { Option } from '../select.component';

export const createSelectModelFromStringArray = (arr: string[]): Option[] =>
  arr.map((item: string) => ({ value: item, label: item }));

export interface ICreateSelectModelPropsSetting {
  labelKey: string;
  valueKey: string;
}

export const createSelectModelFromAnyArray =
  (targetArr: any[], { labelKey, valueKey }: ICreateSelectModelPropsSetting): Option[] =>
  targetArr.map((item: any) => ({ value: item[valueKey], label: item[labelKey] }));
